import * as React from "react"
import { Link, navigate } from "gatsby"
import { isLoggedIn, login } from '../services/auth.js'

import TopSideMenu01 from "../images/topsidemenu01@2x.png"
import TopSideMenu02 from "../images/topsidemenu02@2x.png"

class TopSideMenu extends React.Component {
  state = {
    Password: "",
    MemberNo: "",
    LoginMessage: ""
  }

  handleInputChange = event => {
    const target = event.target
    const value = target.value
    const name = target.name
    this.setState({
      [name]: value,
    })
  }

  handleLogin = async event => {
    event.preventDefault()
    if (await login(this.state)) {
      this.setState({
        Password: "",
        MemberNo: "",
        IsFailed: false
      });
      // ログイン成功時は会員ページに遷移
      navigate('/userpage')
    } else {
      this.setState({
        IsFailed: true
      });
    }
  }

  render = () => {
    const errorField = this.state.IsFailed ? <div className="err aC">会員番号またはパスワードが<br />正しくありません。</div> : ""
    if (typeof window === 'undefined') {
      // サーバサイドレンダリングはしない
      return null
    }
    if (isLoggedIn()) {
      // ログイン済み用サイドメニュー
      return (
        <div id="top_sidemenu">
          <div className="top_contact_btn">
            <Link to="/contact">ご意見・ご要望</Link>
          </div>
          <div className="top_side_subMenu">
            <ul>
              <li>
                <Link to="/emag">
                  <img src={TopSideMenu01} alt="協会誌電子版" className="imgFit"></img>
                </Link>
              </li>
              <li>
                <Link to="https://opac.jp.net/Opac/search.htm?s=ZH7sRNxNfCs4qz33wJK3MYs_-xh">
                  <img src={TopSideMenu02} alt="協会誌/図書検索" className="imgFit"></img>
                </Link>
              </li>
            </ul>
          </div>
        </div>
      )
    }

    return (
      <div id="top_sidemenu">
        <div className="top_login">
          <p className="top_login_notice mb20 aC">
            <span className="noticeTxt strong">ここは保安講習会に関する<br />ＩＤ/ＰＷではありません</span><br />
            <Link to="/training" className="linkTxt">保安講習会はこちら</Link>
          </p>
          <h2 className="top_login_head">会員ログイン</h2>
          <form className="top_login_form" onSubmit={this.handleLogin}>
            <div className="top_login_inner">
              <label htmlFor="member_no">会員番号</label>
              <input id="member_no" type="text" className="top_login_input" name="MemberNo" value={this.state.MemberNo} onChange={this.handleInputChange}></input>
            </div>
            <div className="top_login_inner">
              <label htmlFor="password">パスワード</label>
              <input id="password" type="password" className="top_login_input" name="Password" value={this.state.Password} onChange={this.handleInputChange}></input>
            </div>
            {errorField}
            <div className="top_login_btn">
              <input type="submit" value="ログイン" ></input>
            </div>
          </form>
          <p className="top_login_notice">このページは会員専用ページです。<br />
            会員でない方はご入会をお勧めいたします。入会のご案内は<Link to="/member">こちら</Link><br />
            会員番号・パスワードがわからない方は<Link to="/remindNumber">こちら</Link>からお問い合わせください。
          </p>
        </div>
        <div className="top_contact_btn">
          <Link to="/contact">ご意見・ご要望</Link>
        </div>
        <div className="top_side_subMenu">
          <ul>
            <li>
              <Link to="/emag">
                <img src={TopSideMenu01} alt="協会誌電子版" className="imgFit"></img>
              </Link>
            </li>
            <li>
              <Link to="https://opac.jp.net/Opac/search.htm?s=ZH7sRNxNfCs4qz33wJK3MYs_-xh">
                <img src={TopSideMenu02} alt="協会誌/図書検索" className="imgFit"></img>
              </Link>
            </li>
          </ul>
        </div>
      </div>
    )

  }
}

export default TopSideMenu

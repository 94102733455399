import React from "react";
import { useStaticQuery, graphql } from "gatsby";

import Main1 from "../common/images/main1.jpg"
import Main2 from "../common/images/main2.jpg"
import Main3 from "../common/images/main3.jpg"
import Main4 from "../common/images/main4.jpg"
import Main5 from "../common/images/main5.jpg"

const defaultImages = [Main1, Main2, Main3, Main4, Main5]

const TopImage = () => {
  const data = useData();
  const imageList = {
    image: data.allWpTopSetting.edges[0]
  }

  const imageDatas = []
  for (let i = 1; i < 6; i++) {
    const key = 'image' + i
    if (imageList.image.node.imageSettings[key] !== null)
      imageDatas.push(imageList.image.node.imageSettings[key].localFile.publicURL)
  }

  const imagePaths = imageDatas.length ? imageDatas : defaultImages
  const imageHTML = imagePaths.map((path,index) => {
    return (
      <img key={index} src={path} alt="" className="imgFit"></img>
    )
  })
  return (
    <div id="top_image" className="mb10">
      {imageHTML}
    </div>
  )
}

function useData() {
  return useStaticQuery(graphql`
        query {
            allWpTopSetting(sort: {fields: date, order: DESC} ,limit: 1) {
                edges {
                  node {
                    date 
                    imageSettings {
                        image1 {
                          localFile {
                            publicURL
                          }
                        }
                        image2 {
                          localFile {
                            publicURL
                          }
                        }
                        image3 {
                          localFile {
                            publicURL
                          }
                        }
                        image4 {
                          localFile {
                            publicURL
                          }
                        }
                        image5 {
                          localFile {
                            publicURL
                          }
                        }
                        image6 {
                          localFile {
                            publicURL
                          }
                        }
                    }
                  }
                }
              }
            }`);
}
export default TopImage;